import * as React from "react"
import Footer from "../../components/Footer"
import Layout from "../../components/Layout"
import NavBar from "../../components/NavBar"
import ContactForm from "../../components/pages/contact/ContactForm"
import SEO from "../../components/Seo"
import { IPageProps } from "../../typings/gatsbyPage"

const Entreprises: React.FunctionComponent<IPageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="Contact Entreprises"
        description="Formulaire de contact pour les entreprises"
        keywords={["contact", "entreprise", "cantine", "self"]}
      />
      <NavBar activePage="contact" />
      <ContactForm contactType="entreprise" locationHash={location.hash} />
      <Footer />
    </Layout>
  )
}

export default Entreprises
